import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';

import CTAButtons from '$landing-page/components/cta-buttons';
import FeatureItem from '$landing-page/components/feature-item';
import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

export const pageQuery = graphql`
    query featurePage($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                description
                items {
                    title
                    description
                    image {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 100, placeholder: NONE)
                        }
                    }
                }
            }
        }
    }
`;

const FeaturePage = (props) => {
    const { location, data } = props;

    const classes = useStyles();

    const entry = data.markdownRemark;
    const firstItem = entry.frontmatter.items[0];

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title={entry.frontmatter.title}
            description={entry.frontmatter.description}
            content={
                <FeatureItem
                    key={firstItem.title}
                    title={firstItem.title}
                    description={firstItem.description}
                    image={getImage(firstItem.image)}
                    headerItem
                    classes={{
                        root: classes.firstFeatureItemRoot,
                        title: classes.firstFeatureItemTitle,
                        image: classes.firstFeatureItemImage,
                    }}
                    absolutePositionedImage
                    actionButtons={<CTAButtons />}
                />
            }
        >
            <div>
                {entry.frontmatter.items.map(
                    (item, index) =>
                        index > 0 && (
                            <FeatureItem
                                direction={index % 2 === 1 ? 'row-reverse' : 'row'}
                                key={item.title}
                                title={item.title}
                                description={item.description}
                                image={getImage(item.image)}
                            />
                        )
                )}
            </div>
        </BigContentHeaderLayout>
    );
};

FeaturePage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                items: PropTypes.oneOfType([PropTypes.array]),
                path: PropTypes.string,
            }),
            html: PropTypes.string,
        }),
    }).isRequired,
};

export default FeaturePage;
