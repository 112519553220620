import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    firstFeatureItemRoot: {
        marginTop: 0,
        marginBottom: 0,
    },
    firstFeatureItemTitle: {
        color: '#081f4d !important',
    },
    firstFeatureItemImage: {
        [theme.breakpoints.down('xs')]: {
            width: '110%',
            marginTop: theme.spacing(-3),
            marginLeft: theme.spacing(-1),
        },
        [theme.breakpoints.up('xs')]: {
            maxHeight: '400px',
            '& [data-main-image]': {
                objectFit: 'contain !important',
            },
        },
        [theme.breakpoints.up('sm')]: {
            width: '120%',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
        },
    },
}));
